import React, { ChangeEvent, useEffect, useState } from "react";
import "../../styles.scss";
import { LocationPurchaseOrder } from "./LocationPurchaseOrder";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { Form, Input } from "antd";
import QuicstockSearch from "../../../../assets/svgs/quicstock-search.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { ProductItemModel } from "../../../../model/product.model";

const LocationPurchaseOrders = () => {
  const [pos, setPos] = useState<ProductItemModel[]>([]);
  const [search] = useState<string>("");
  const productPos = useSelector(
    (state: RootState) => state.products.productPos
  );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const poss: ProductItemModel[] = productPos.filter((po) =>
        po.po_number?.toLowerCase().includes(e.target.value)
      );
      setPos(poss);
    } else {
      setPos(productPos);
    }
  };

  useEffect(() => {
    setPos(productPos);
  }, [productPos]);
  return (
    <div className="w-full mt-16">
      <div className="search-field !w-full">
        <FieldContainer
          icon={QuicstockSearch}
          iconPlacementCss={"location-purchase-orders-search"}
        >
          <Form.Item name={"search"} className="page-header">
            <Input
              type={"email"}
              className={"form-field_input_2 search w-full"}
              value={search || ""}
              placeholder={"Search"}
              onChange={handleSearchChange}
            />
          </Form.Item>
        </FieldContainer>
      </div>
      <div className="w-full flex flex-col gap-y-4 mt-4">
        {pos?.map((po) => (
          <LocationPurchaseOrder
            orderNo={po?.po_number ?? "---"}
            dateCompleted={"12/02/24"}
            hideCheck={true}
            key={po?.po_number}
            quantity={po.quantity}
          />
        ))}
      </div>
    </div>
  );
};

export { LocationPurchaseOrders };
