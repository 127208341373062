/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from "react";
import "../../styles.scss";
import { IPurchaseFormField } from "../../model";
import { Field } from "formik";
import { AutoComplete, Form, Input, Select, Spin } from "antd";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import FieldName from "../../../../components/FieldName";
import QuicstockNaira from "../../../../assets/svgs/quicstock-naira.svg";
import QuicstockInfo from "../../../../assets/svgs/quicstock-info.svg";
import { formatCurrency } from "../../../../utils/constants";
import { ReactComponent as QuicstockMinus } from "../../../../assets/svgs/quicstock-minus.svg";
import { useDispatch, useSelector } from "react-redux";
import { ApiDropdownModel } from "../../../../model/base.model";
import {
  FetchProductUnit,
  SearchProducts,
} from "../../../../redux/actions/products.action";
import { RootState } from "../../../../redux/reducers";
import { AppDispatch } from "../../../../redux/store";
import { debounce } from "lodash";
import { UnitItemModel } from "../../../../model/product.model";

const PurchaseFormField: FC<IPurchaseFormField> = ({
  index,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  remove,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [selectedProductName, setSelectedProductName] = useState("");
  const [options, setOptions] = useState<ApiDropdownModel[]>([]);
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const productSearchResult = useSelector(
    (state: RootState) => state.products.productSearchResult
  );
  const [productId, setProductId] = useState<string>("");
  const [unitOptions, setUnitOptions] = useState<ApiDropdownModel[]>([
    { value: "0", label: "Pieces", data: null },
  ]);

  const debouncedSearch = debounce(
    async (value, setOptions, dispatch, setSelectedProductName) => {
      if (value) {
        dispatch(SearchProducts(value));
      } else {
        setOptions([]);
        setSelectedProductName("");
      }
    },
    300
  );

  const handleSearch = useCallback(
    (value: string) => {
      debouncedSearch(value, setOptions, dispatch, setSelectedProductName);
    },
    [setOptions, dispatch, setSelectedProductName]
  );

  useEffect(() => {
    if (productSearchResult && productSearchResult.length) {
      const suggestions: ApiDropdownModel[] | any[] = productSearchResult.map(
        (product) => ({
          value: product.id,
          label: product.name,
          name: product.name,
        })
      );
      setOptions(suggestions);
    }
  }, [productSearchResult]);

  useEffect(() => {
    if (productId) {
      dispatch(FetchProductUnit(productId))
        .then((res) => {
          const uom: UnitItemModel[] = res?.data?.uom ?? [];
          const suggestions: ApiDropdownModel[] | any[] = uom.map((u) => ({
            value: u?.id,
            label: u.name,
            data: u,
          }));
          suggestions.unshift({ value: "0", label: "Pieces", data: {} });
          setUnitOptions(suggestions);
        })
        .catch((err: any) => {});
    } else {
      setUnitOptions([]);
    }
  }, [productId, dispatch]);

  return (
    <div className={"grid sm:grid-cols-12 grid-cols-1 gap-x-4"}>
      <div className="mb-5 xl:col-span-3 sm:col-span-3 col">
        <FieldContainer iconPlacementCss={"customFieldIcon"}>
          <FieldName name={"Item"} required />
          <Form.Item
            name={`items[${index}].item`}
            validateStatus={
              touched.items?.[index]?.item &&
              (errors as any).items?.[index]?.item
                ? "error"
                : "success"
            }
            help={
              touched.items?.[index]?.item &&
              (errors as any).items?.[index]?.item
                ? (errors as any).items?.[index]?.item
                : ""
            }
          >
            <Field>
              {({ form: { setFieldValue } }: any) => (
                <AutoComplete
                  options={options}
                  onSearch={handleSearch}
                  onSelect={(value, option) => {
                    setFieldValue(`items[${index}].item`, value);
                    setSelectedProductName(option.name ?? "");
                    setProductId(value);
                    setFieldValue(`items[${index}].uom_id`, "");
                    setFieldValue(`items[${index}].factor`, "");
                    setFieldValue(`items[${index}].uom`, null);
                  }}
                  onChange={(value) => {
                    setSelectedProductName(value);
                    setFieldValue(`items[${index}].item`, "");
                    setProductId("");
                    setFieldValue(`items[${index}].item`, "");
                    setFieldValue(`items[${index}].uom_id`, "");
                    setFieldValue(`items[${index}].factor`, "");
                    setFieldValue(`items[${index}].uom`, null);
                    if (!value) {
                      setOptions([]);
                    }
                  }}
                  value={selectedProductName || values?.items[index].item}
                  notFoundContent={isLoading ? <Spin size="small" /> : null}
                  allowClear
                  style={{ width: "100%", height: "48px" }}
                >
                  <input
                    type="text"
                    className="form-field_input_2"
                    placeholder="Search product"
                  />
                </AutoComplete>
              )}
            </Field>
          </Form.Item>
        </FieldContainer>
      </div>
      <div className="xl:col-span-8 sm:col-span-8 col">
        <div className="w-full grid grid-cols-12 gap-x-4">
          <div className={"mb-5 sm:col-span-3 col-span-6"}>
            <FieldContainer
              iconPlacementCss={"customFieldIcon"}
              icon={QuicstockNaira}
            >
              <FieldName name={"Unit price"} required />
              <Form.Item
                name={`items[${index}].unit_price`}
                validateStatus={
                  touched.items?.[index]?.unit_price &&
                  (errors as any).items?.[index]?.unit_price
                    ? "error"
                    : "success"
                }
                help={
                  touched.items?.[index]?.item &&
                  (errors as any).items?.[index]?.unit_price
                    ? (errors as any).items?.[index]?.unit_price
                    : ""
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Input
                      type={"text"}
                      className={"form-field_input_2"}
                      value={values?.items[index].unit_price || ""}
                      onChange={(e) => {
                        handleChange(e);
                        const inputValue = e.target.value;
                        const formattedValue = formatCurrency(inputValue);
                        setFieldValue(
                          `items[${index}].unit_price`,
                          formattedValue
                        );

                        // Automatically calculate and set the 'total' value
                        const unitPrice = parseFloat(
                          formattedValue.split(",").join("")
                        );
                        const quantity = parseFloat(
                          values.items[index].quantity
                        );
                        const total = unitPrice * quantity;

                        if (!isNaN(total)) {
                          const formattedValue = formatCurrency(
                            total.toString()
                          );
                          setFieldValue(
                            `items[${index}].total`,
                            formattedValue
                          );
                        } else {
                          setFieldValue(`items[${index}].total`, "");
                        }
                      }}
                      onBlur={handleBlur}
                      placeholder="0.00"
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
          <div className={"mb-5 sm:col-span-4 col-span-6"}>
            <FieldContainer
              iconPlacementCss={"customFieldIcon"}
              icon={
                values.items[index].uom_id && values.items[index].uom_id !== "0"
                  ? QuicstockInfo
                  : null
              }
              tooltip={
                values.items[index].uom
                  ? `A ${values.items[index].uom?.name?.toLowerCase()} is ${values.items[index].uom?.factor} unit(s)`
                  : ""
              }
            >
              <FieldName name={"Unit of measurement"} required />
              <Form.Item
                name={`items[${index}].uom_id`}
                validateStatus={
                  touched.items?.[index]?.uom_id &&
                  (errors as any).items?.[index]?.uom_id
                    ? "error"
                    : "success"
                }
                help={
                  touched.items?.[index]?.uom_id &&
                  (errors as any).items?.[index]?.uom_id
                    ? (errors as any).items?.[index]?.uom_id
                    : ""
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Select
                      placeholder={"Select unit of measurement"}
                      value={values?.items[index].uom_id || null}
                      onBlur={handleBlur}
                      className={"form-field_input_3"}
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue(`items[${index}].uom_id`, e);
                        const uom = unitOptions.find((u) => u.value === e);
                        setFieldValue(
                          `items[${index}].factor`,
                          uom?.data?.factor
                        );
                        setFieldValue(`items[${index}].uom`, uom?.data);
                      }}
                      options={unitOptions}
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
          <div className={"mb-5 sm:col-span-2 col-span-6"}>
            <FieldContainer iconPlacementCss={"iconCss"}>
              <FieldName name={"Quantity"} required />
              <Form.Item
                name={`items[${index}].quantity`}
                validateStatus={
                  touched.items?.[index]?.quantity &&
                  (errors as any).items?.[index]?.quantity
                    ? "error"
                    : "success"
                }
                help={
                  touched.items?.[index]?.quantity &&
                  (errors as any).items?.[index]?.quantity
                    ? (errors as any).items?.[index]?.quantity
                    : ""
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Input
                      type={"number"}
                      className={"form-field_input_2"}
                      value={values?.items[index].quantity || ""}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue(
                          `items[${index}].quantity`,
                          e.target.value
                        );

                        // Automatically calculate and set the 'total' value
                        const unitPrice = parseFloat(
                          values.items[index].unit_price.split(",").join("")
                        );
                        const quantity = parseFloat(e.target.value);
                        const total = unitPrice * quantity;

                        if (!isNaN(total)) {
                          const formattedValue = formatCurrency(
                            total.toString()
                          );
                          setFieldValue(
                            `items[${index}].total`,
                            formattedValue
                          );
                        } else {
                          setFieldValue(`items[${index}].total`, "");
                        }
                      }}
                      onBlur={handleBlur}
                      placeholder="0"
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
          <div className={"mb-5 sm:col-span-3 col-span-6"}>
            <FieldContainer
              iconPlacementCss={"customFieldIcon"}
              icon={QuicstockNaira}
            >
              <FieldName name={"Total"} required />
              <Form.Item
                name={`items[${index}].total`}
                validateStatus={
                  touched.items?.[index]?.total &&
                  (errors as any).items?.[index]?.total
                    ? "error"
                    : "success"
                }
                help={
                  touched.items?.[index]?.total &&
                  (errors as any).items?.[index]?.total
                    ? (errors as any).items?.[index]?.total
                    : ""
                }
              >
                <Field>
                  {({ form: { setFieldValue } }: any) => (
                    <Input
                      type={"text"}
                      className={"form-field_input_2"}
                      value={values?.items[index].total || ""}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue(`items[${index}].total`, e.target.value);
                      }}
                      onBlur={handleBlur}
                      placeholder="0.00"
                      disabled
                    />
                  )}
                </Field>
              </Form.Item>
            </FieldContainer>
          </div>
        </div>
      </div>
      <div className="sm:col-span-1 col">
        <div className="w-full flex justify-end items-center h-full">
          <button
            type="button"
            className="h-[40px] sm:w-[40px] w-full border border-dark-200 rounded-full px-2 py-3 flex justify-center items-center mb-5"
            onClick={remove}
          >
            <span className="sm:block hidden">
              <QuicstockMinus />
            </span>
            <span className="sm:hidden block text-error-main">Remove</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export { PurchaseFormField };
