import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { ILocationForm } from "../../model";
import { Field, Formik } from "formik";
import { Form, Input, Switch } from "antd";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import FieldName from "../../../../components/FieldName";
import { LocationItemModel } from "../../../../model/location.model";
const { TextArea } = Input;

const LocationForm: FC<ILocationForm> = ({
  submit,
  cancel,
  location,
  type,
}) => {
  const [values, setValues] = useState<LocationItemModel>({
    name: "",
    location_code: "",
    description: "",
    location_type: "",
    exclude_location: false,
  });
  const [form] = Form.useForm();

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    submit(values);
  };

  useEffect(() => {
    if (location) {
      setValues(location);
    }
  }, [location]);

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className={"w-full drawer-content-height"}>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Location name"} required />
                <Form.Item
                  name={"name"}
                  help={touched.name && errors.name ? errors.name : ""}
                  validateStatus={
                    touched.name && errors.name ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.name}
                        onChange={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        placeholder="Enter location name"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Location code"} />
                <Form.Item
                  name={"location_code"}
                  help={
                    touched.location_code && errors.location_code
                      ? errors.location_code
                      : ""
                  }
                  validateStatus={
                    touched.location_code && errors.location_code
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.location_code || ""}
                        onChange={(e) => {
                          setFieldValue("location_code", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("location_code", e.target.value);
                        }}
                        placeholder="Enter location code"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Location description"} required />
                <Form.Item
                  name={"description"}
                  help={
                    touched.description && errors.description
                      ? errors.description
                      : ""
                  }
                  validateStatus={
                    touched.description && errors.description
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <TextArea
                        className={"form-field_input_2"}
                        rows={6}
                        value={values?.description || ""}
                        onChange={(e) => {
                          setFieldValue("description", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("description", e.target.value);
                        }}
                        placeholder="Enter a description about the location"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            {type === "ONLINE" && (
              <>
                <div className="my-4 border-[0.5px] border-[#EFEFEF]"></div>
                <div className={"mb-5"}>
                  <FieldContainer iconPlacementCss={"iconCss"}>
                    <Form.Item
                      name={"exclude_location"}
                      help={
                        touched.exclude_location && errors.exclude_location
                          ? errors.exclude_location
                          : ""
                      }
                      validateStatus={
                        touched.exclude_location && errors.exclude_location
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }: any) => (
                          <div className="flex justify-between items-center">
                            <p>
                              Exclude this location from showing up on the user
                              facing platform
                            </p>
                            <Switch
                              size="small"
                              value={values?.exclude_location || false}
                              onChange={(e) => {
                                setFieldValue("exclude_location", e);
                              }}
                            />
                          </div>
                        )}
                      </Field>
                    </Form.Item>
                  </FieldContainer>
                </div>
              </>
            )}
          </div>
          <div className="w-full left-0 right-0 bottom-0 bg-white">
            <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
            <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
              <div className={"col-1 sm:block hidden"}></div>
              <div className={"col-1"}>
                <Button
                  css={
                    "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                  }
                  state={ButtonState.PRIMARY}
                  text={"Cancel"}
                  type={"button"}
                  onClick={cancel}
                />
              </div>
              <div className={"col-1"}>
                <Button
                  css={"w-full !rounded-[6px]"}
                  state={ButtonState.PRIMARY}
                  text={"Preview"}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validator = yup.object().shape({
  name: yup
    .string()
    .min(2, "Location name is too short")
    .max(15, "Location name should not be more than 15 characters")
    .required("Location name is required"),
  // description: yup.string().required("Description about location is required"),
});

export { LocationForm };
