/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import {
  IConfirmProductForm,
  IConfirmProductFormField,
  IConfirmProductFormProductField,
} from "../../model";
import { Field, FieldArray, Formik } from "formik";
import { AutoComplete, Form, Input, Spin } from "antd";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { ReactComponent as QuicstockFormAdd } from "../../../../assets/svgs/quicstock-form-add.svg";
import { ReactComponent as QuicstockMinus } from "../../../../assets/svgs/quicstock-minus.svg";
import { AppDispatch } from "../../../../redux/store";
import { ApiDropdownModel } from "../../../../model/base.model";
import { debounce } from "lodash";
import { SearchLocations } from "../../../../redux/actions/locations.action";
import openNotification from "../../../../components/Notification/notification";
import NotificationMessage from "../../../../components/Notification";
import FieldName from "../../../../components/FieldName";
const { TextArea } = Input;

const ConfirmProductForm: FC<IConfirmProductForm> = ({ submit, cancel }) => {
  const dispatch: AppDispatch = useDispatch();
  const po = useSelector((state: RootState) => state.pos.po);
  const [options, setOptions] = useState<ApiDropdownModel[]>([]);
  const isLoading = useSelector(
    (state: RootState) => state.locations.isLocationActionLoading
  );
  const locationSearchResult = useSelector(
    (state: RootState) => state.locations.locationSearchResult
  );
  const [values, setValues] = useState<IConfirmProductFormField>({
    products: [],
    additionalInformation: "",
  });
  const [hasReceivedPos, setHasReceivedPos] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleOnSubmit = (
    values: IConfirmProductFormField,
    { resetForm }: any
  ) => {
    for (let i = 0; i < values?.products?.length; i++) {
      const product = values?.products[i].product;
      const fields = values?.products[i].fields;
      let receivedQuantity = 0;
      for (let j = 0; j < fields.length; j++) {
        receivedQuantity = receivedQuantity + parseInt(fields[j].quantity);
      }
      if (
        (product?.uom?.factor &&
          receivedQuantity >
            (product?.quantity_expected - product?.quantity_received) /
              product?.uom?.factor) ||
        (!product?.uom?.factor &&
          receivedQuantity >
            product?.quantity_expected - product?.quantity_received)
      ) {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={
              "Received quantity exceeds expected qauntity. Please check the form and try again"
            }
          />,
          true
        );
        return;
      }
    }
    submit(values);
  };

  useEffect(() => {
    const products: IConfirmProductFormProductField[] = [];
    if (po && po?.products && po?.products?.length > 0) {
      for (let i = 0; i < po?.products?.length; i++) {
        products.push({ product: po?.products[i], fields: [] });
      }
    }
    setValues({ ...values, products });
  }, [po]);

  const debouncedSearch = debounce(async (value, setOptions) => {
    if (value) {
      dispatch(SearchLocations(value));
    } else {
      setOptions([]);
    }
  }, 300);

  const handleSearch = async (value: string) => {
    debouncedSearch(value, setOptions);
  };

  useEffect(() => {
    if (locationSearchResult && locationSearchResult.length) {
      const suggestions: ApiDropdownModel[] | any[] = locationSearchResult.map(
        (location) => ({
          value: location.id,
          label: location.name,
          name: location.name,
        })
      );
      setOptions(suggestions);
    }
  }, [locationSearchResult]);

  useEffect(() => {
    let counter = 0;
    for (let i = 0; i < values.products.length; i++) {
      for (let j = 0; j < values.products[i].product?.locations?.length; j++) {
        counter++;
      }
    }
    if (counter > 0) {
      setHasReceivedPos(true);
    }
  }, [values]);

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => {
        const addField = (index: number) => {
          const newField = {
            quantity: "",
            location_id: "",
            location_name: "",
            location: null,
          };
          const updatedFields = [...values.products[index].fields, newField];
          setFieldValue(`products[${index}].fields`, updatedFields);
        };

        const removeItem = (index: number, jj: number) => {
          const updatedFields = values.products[index].fields.filter(
            (_, i) => i !== jj
          );
          setFieldValue(`products[${index}].fields`, updatedFields);
        };

        const watchAndHideAddBtn = (index: number): boolean => {
          const product = values?.products[index].product;
          const fields = values?.products[index].fields;
          let receivedQuantity = 0;
          for (let i = 0; i < fields?.length; i++) {
            receivedQuantity = receivedQuantity + parseInt(fields[i].quantity);
          }
          return product?.uom?.factor
            ? receivedQuantity >=
                (product?.quantity_expected - product?.quantity_received) /
                  product?.uom?.factor
            : receivedQuantity >=
                product?.quantity_expected - product?.quantity_received;
        };

        const watchFields = (): number => {
          let size = 0;
          for (let i = 0; i < values?.products?.length; i++) {
            for (let j = 0; j < values?.products[i]?.fields?.length; j++) {
              size++;
            }
          }
          return size;
        };

        return (
          <Form onFinish={handleSubmit} form={form}>
            <div className="w-full drawer-content-height">
              {hasReceivedPos && <p className="mb-2">PO(s) Received</p>}
              {po?.products
                ?.filter((pp) => (pp?.locations?.length || 0) > 0)
                .map((vp) => (
                  <div
                    className={"mb-5 border border-dark-200 p-3 rounded-[10px]"}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-x-8">
                        <div className="flex items-center gap-x-2">
                          <div className="h-[30px] w-[30px] rounded-full bg-gray-800"></div>
                          <p>{vp.name}</p>
                        </div>
                        {vp?.uom?.factor ? (
                          <p>
                            {(vp?.quantity_received as number) /
                              (vp?.uom?.factor as number)}{" "}
                            {vp?.uom?.name}
                          </p>
                        ) : (
                          <p>{vp?.quantity_received} Pieces</p>
                        )}
                      </div>
                    </div>
                    {vp.locations?.map((l) => (
                      <div className="mt-4 grid grid-cols-3 gap-x-4 w-full">
                        <div className="border border-dark-200 p-3 rounded-[10px] bg-dark-100">
                          {l?.quantity}
                        </div>
                        <div className="border border-dark-200 p-3 rounded-[10px] bg-dark-100">
                          {vp?.uom?.name ?? "Pieces"}
                        </div>
                        <div className="border border-dark-200 p-3 rounded-[10px] bg-dark-100">
                          {l?.name}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              <div className={"mb-5"}>
                {hasReceivedPos && <p className="mb-2">Pending PO(s)</p>}
                <FieldArray name="products">
                  {({ push, remove }) => (
                    <>
                      {values?.products?.map((pp, index) => (
                        <div
                          key={pp?.product?.product_id}
                          className={
                            "mb-5 border border-dark-200 p-3 rounded-[10px]"
                          }
                        >
                          <div className="flex justify-between items-center">
                            <div className="flex items-center gap-x-8">
                              <div className="flex items-center gap-x-2">
                                <div className="h-[30px] w-[30px] rounded-full bg-gray-800"></div>
                                <p>{pp?.product?.name}</p>
                              </div>
                              {pp?.product?.uom?.factor ? (
                                <p>
                                  {(pp?.product?.quantity_expected -
                                    pp?.product?.quantity_received) /
                                    pp?.product?.uom?.factor}{" "}
                                  {pp?.product?.uom?.name}
                                </p>
                              ) : (
                                <p>
                                  {pp?.product?.quantity_expected -
                                    pp?.product?.quantity_received}{" "}
                                  Pieces
                                </p>
                              )}
                            </div>
                            {!watchAndHideAddBtn(index) && (
                              <QuicstockFormAdd
                                className="cursor-pointer"
                                onClick={() => addField(index)}
                              />
                            )}
                          </div>
                          <FieldArray name={`products[${index}].fields`}>
                            {({ push, remove }) => (
                              <>
                                {pp?.fields.map((item, jj) => (
                                  <div
                                    key={jj}
                                    className="mt-4 grid grid-cols-12 gap-x-2"
                                  >
                                    <div className="col-span-4">
                                      <FieldContainer
                                        iconPlacementCss={"iconCss"}
                                      >
                                        <Form.Item
                                          name={`products[${index}].fields[${jj}].quantity`}
                                          validateStatus={
                                            touched.products?.[index]?.fields?.[
                                              jj
                                            ]?.quantity &&
                                            (errors as any).products &&
                                            (errors as any).products?.[index] &&
                                            (errors as any).products?.[index]
                                              .fields?.[jj]?.quantity
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            touched.products?.[index]?.fields?.[
                                              jj
                                            ]?.quantity &&
                                            (errors as any).products &&
                                            (errors as any).products?.[index] &&
                                            (errors as any).products?.[index]
                                              .fields?.[jj]?.quantity
                                              ? (errors as any).products?.[
                                                  index
                                                ].fields?.[jj]?.quantity
                                              : ""
                                          }
                                          className="m-0"
                                        >
                                          <Field>
                                            {({
                                              form: { setFieldValue },
                                            }: any) => (
                                              <Input
                                                type={"number"}
                                                className={"form-field_input_2"}
                                                value={
                                                  values?.products?.[index]
                                                    .fields?.[jj]?.quantity ||
                                                  ""
                                                }
                                                onChange={(e) => {
                                                  if (
                                                    !/^\d*$/.test(
                                                      e.target.value
                                                    )
                                                  ) {
                                                    e.target.value = "";
                                                  }
                                                  handleChange(e);
                                                  setFieldValue(
                                                    `products[${index}].fields[${jj}].quantity`,
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={handleBlur}
                                                placeholder="0"
                                              />
                                            )}
                                          </Field>
                                        </Form.Item>
                                      </FieldContainer>
                                    </div>
                                    <div className="col-span-3">
                                      <FieldContainer
                                        iconPlacementCss={"iconCss"}
                                      >
                                        <Form.Item className="m-0">
                                          <Field>
                                            {({
                                              form: { setFieldValue },
                                            }: any) => (
                                              <Input
                                                type={"text"}
                                                className={"form-field_input_2"}
                                                value={
                                                  pp.product?.uom?.name ||
                                                  "Pieces"
                                                }
                                                disabled
                                              />
                                            )}
                                          </Field>
                                        </Form.Item>
                                      </FieldContainer>
                                    </div>
                                    <div className="col-span-4">
                                      <FieldContainer
                                        iconPlacementCss={"iconCss"}
                                      >
                                        <Form.Item
                                          name={`products[${index}].fields[${jj}].location_id`}
                                          validateStatus={
                                            touched.products?.[index]?.fields?.[
                                              jj
                                            ]?.location_id &&
                                            (errors as any).products &&
                                            (errors as any).products?.[index] &&
                                            (errors as any).products?.[index]
                                              .fields?.[jj]?.location_id
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            touched.products?.[index]?.fields?.[
                                              jj
                                            ]?.location_id &&
                                            (errors as any).products &&
                                            (errors as any).products?.[index] &&
                                            (errors as any).products?.[index]
                                              .fields?.[jj]?.location_id
                                              ? (errors as any).products?.[
                                                  index
                                                ].fields?.[jj]?.location_id
                                              : ""
                                          }
                                          className="m-0"
                                        >
                                          <Field>
                                            {({
                                              form: { setFieldValue },
                                            }: any) => (
                                              <AutoComplete
                                                options={options}
                                                onSearch={handleSearch}
                                                onSelect={(value, option) => {
                                                  setFieldValue(
                                                    `products[${index}].fields[${jj}].location_id`,
                                                    value
                                                  );
                                                  setFieldValue(
                                                    `products[${index}].fields[${jj}].location_name`,
                                                    option.name ?? ""
                                                  );
                                                }}
                                                onChange={(value) => {
                                                  setFieldValue(
                                                    `products[${index}].fields[${jj}].location_name`,
                                                    value
                                                  );
                                                  setFieldValue(
                                                    `products[${index}].fields[${jj}].location_id`,
                                                    ""
                                                  );
                                                  if (!value) {
                                                    setOptions([]);
                                                  }
                                                }}
                                                value={
                                                  values.products?.[index]
                                                    ?.fields?.[jj]
                                                    ?.location_name ||
                                                  values.products?.[index]
                                                    ?.fields?.[jj]
                                                    ?.location_id ||
                                                  ""
                                                }
                                                notFoundContent={
                                                  isLoading ? (
                                                    <Spin size="small" />
                                                  ) : null
                                                }
                                                allowClear
                                                style={{
                                                  width: "100%",
                                                  height: "48px",
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  className="form-field_input_2"
                                                  placeholder="Search location"
                                                />
                                              </AutoComplete>
                                            )}
                                          </Field>
                                        </Form.Item>
                                      </FieldContainer>
                                    </div>
                                    <div className="col-span-1">
                                      <div className="w-full flex justify-end items-center h-full">
                                        <button
                                          type="button"
                                          className="h-[40px] w-[40px] border border-dark-200 rounded-full px-2 py-3 flex justify-center items-center"
                                          onClick={() => removeItem(index, jj)}
                                        >
                                          <QuicstockMinus />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                          </FieldArray>
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
              </div>
              <div className={"mb-5"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FieldName name={"Information (Optional)"} />
                  <Form.Item
                    name={"additionalInformation"}
                    help={
                      touched.additionalInformation &&
                      errors.additionalInformation
                        ? errors.additionalInformation
                        : ""
                    }
                    validateStatus={
                      touched.additionalInformation &&
                      errors.additionalInformation
                        ? "error"
                        : "success"
                    }
                  >
                    <Field>
                      {({ form: { setFieldValue } }: any) => (
                        <TextArea
                          className={"form-field_input_2"}
                          rows={6}
                          value={values?.additionalInformation || ""}
                          onChange={(e) => {
                            setFieldValue(
                              "additionalInformation",
                              e.target.value
                            );
                          }}
                          onBlur={(e) => {
                            setFieldValue(
                              "additionalInformation",
                              e.target.value
                            );
                          }}
                          placeholder="Enter additional information"
                        />
                      )}
                    </Field>
                  </Form.Item>
                </FieldContainer>
              </div>
            </div>
            <div className="w-full left-0 right-0 bottom-0 bg-white">
              <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
              <div className={"grid grid-cols-3 gap-x-2"}>
                <div className={"col-1"}></div>
                <div className={"col-1"}>
                  <Button
                    css={
                      "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                    }
                    state={ButtonState.PRIMARY}
                    text={"Cancel"}
                    type={"button"}
                    onClick={cancel}
                  />
                </div>
                <div className={"col-1"}>
                  <Button
                    css={"w-full !rounded-[6px]"}
                    state={ButtonState.PRIMARY}
                    text={"Preview"}
                    type={"submit"}
                    disabled={watchFields() === 0}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const validator = yup.object().shape({
  products: yup.array().of(
    yup.object({
      fields: yup.array().of(
        yup.object().shape({
          quantity: yup
            .number()
            .min(1, "Quantity cannot be less than 1")
            .required("Quantity is required"),
          location_id: yup.string().required("Location is required"),
        })
      ),
    })
  ),
});

export { ConfirmProductForm };
