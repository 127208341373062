import { ThunkAction } from "redux-thunk";
import { confirmPo, createPo, getPo, getPos } from "../_api/index";
import { RootState } from "../reducers";
import { Action } from "redux";
import {
  CreatePoRequestModel,
  PoComfirmationModel,
} from "../../model/po.model";
import { ApiPaginationModel } from "../../model/base.model";

export const Types = {
  CREATE_PO_START: "CREATE_PO_START",
  CREATE_PO_SUCCESS: "CREATE_PO_SUCCESS",
  CREATE_PO_END: "CREATE_PO_END",

  FETCH_PO_START: "FETCH_PO_START",
  FETCH_PO_SUCCESS: "FETCH_PO_SUCCESS",
  FETCH_PO_END: "FETCH_PO_END",

  CONFIRM_PO_START: "CONFIRM_PO_START",
  CONFIRM_PO_SUCCESS: "CONFIRM_PO_SUCCESS",
  CONFIRM_PO_END: "CONFIRM_PO_END",

  FETCH_POS_START: "FETCH_POS_START",
  FETCH_POS_SUCCESS: "FETCH_POS_SUCCESS",
  FETCH_POS_END: "FETCH_POS_END",
};

export const CreatePo = (
  req: CreatePoRequestModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.CREATE_PO_START });
    return new Promise((resolve, reject) => {
      createPo(req)
        .then(({ data }) => {
          dispatch({ type: Types.CREATE_PO_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.CREATE_PO_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const FetchPo = (
  id: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_PO_START });
    return new Promise((resolve, reject) => {
      getPo(id)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_PO_SUCCESS,
            po: data?.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_PO_END,
            message: response?.data?.message || "An error occured",
          });
        });
    });
  };
};

export const ConfirmPo = (
  req: PoComfirmationModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.CONFIRM_PO_START });
    return new Promise((resolve, reject) => {
      confirmPo(req)
        .then(({ data }) => {
          dispatch({ type: Types.CONFIRM_PO_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.CONFIRM_PO_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const FetchPos = (
  params: ApiPaginationModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_POS_START });
    return new Promise((resolve, reject) => {
      getPos(params)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_POS_SUCCESS,
            allPOs: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_POS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};
