/* eslint-disable @typescript-eslint/no-unused-vars */
import { Table } from "antd";
import React, { FC, useState } from "react";
import "../../styles.scss";
import { IUserLog } from "../../model";
import { USERS_LOGS } from "../../../../utils/misc/database";
import { itemRender } from "../../../../utils/constants";
import TableActions from "../../../../components/TableActions";

const userLogs: IUserLog[] = USERS_LOGS;

const AllLogs: FC = () => {
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const columns = [
    {
      title: "Full name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Staff ID",
      dataIndex: "staffId",
      key: "staffId",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Activity type",
      dataIndex: "activityType",
      key: "activityType",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];

  const data =
    userLogs &&
    userLogs?.length > 0 &&
    userLogs?.map((log, index) => {
      return {
        key: index,
        name: log.name ?? "---",
        staffId: log?.staffId ?? "---",
        action: log?.action ?? "---",
        activityType: log?.activityType ?? "---",
        role: log?.role ?? "---",
        time: log?.time ?? "---",
        date: log?.date ?? "---",
        id: log?.id,
        log: log,
      };
    });

  const onShowSizeChange = (current: number, pageSize: number) => {};

  const paginationChangeHandler = (current: number, pageSize: number) => {};

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const handleExport = () => {};
  const handleFilter = () => {};
  const handleDelete = () => {};

  return (
    <>
      <TableActions
        label={"Actions you can perform"}
        handleExport={handleExport}
        handleFilter={handleFilter}
        handleDelete={handleDelete}
      />
      <div className="w-full bg-white extra-filter-table pb-4 overflow-hidden mt-10">
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data as any}
          size={"small"}
          className={"d-table"}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40"],
            showSizeChanger: false,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: userLogs?.length,
            pageSize: defaultPageSize,
            showTotal: (total) =>
              `Showing 1 to ${defaultCurrent * defaultPageSize} of ${total} entries`,
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender: (_, type, originalElement) =>
              itemRender(
                _,
                type,
                originalElement,
                userLogs?.length,
                defaultPageSize
              ),
          }}
        />
      </div>
    </>
  );
};

export { AllLogs };
