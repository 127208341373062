import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { IUserForm } from "../../model";
import { Field, Formik } from "formik";
import { Form, Input, Select } from "antd";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import FieldName from "../../../../components/FieldName";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumber } from "../../../../utils/constants";
import { UserItemModel } from "../../../../model/user.model";

const UserForm: FC<IUserForm> = ({ submit, cancel, user }) => {
  const [values, setValues] = useState<UserItemModel>({
    email: "",
    first_name: "",
    last_name: "",
    phonenumber: "",
    role: "",
  });
  const [form] = Form.useForm();

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    if (user) {
      submit(values, "UPDATE");
    } else {
      submit(values, "ADD");
    }
  };

  useEffect(() => {
    if (user) {
      setValues({
        ...user,
        phonenumber: parsePhoneNumber(user.phonenumber ?? ""),
      });
    }
  }, [user]);

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit, handleBlur }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className={`w-full drawer-content-height`}>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"First name"} required />
                <Form.Item
                  name={"first_name"}
                  help={
                    touched.first_name && errors.first_name
                      ? errors.first_name
                      : ""
                  }
                  validateStatus={
                    touched.first_name && errors.first_name
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.first_name}
                        onChange={(e) => {
                          setFieldValue("first_name", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("first_name", e.target.value);
                        }}
                        placeholder="Enter first name"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Last name"} required />
                <Form.Item
                  name={"last_name"}
                  help={
                    touched.last_name && errors.last_name
                      ? errors.last_name
                      : ""
                  }
                  validateStatus={
                    touched.last_name && errors.last_name ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.last_name}
                        onChange={(e) => {
                          setFieldValue("last_name", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("last_name", e.target.value);
                        }}
                        placeholder="Enter last name"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Email address"} required />
                <Form.Item
                  name={"email"}
                  help={touched.email && errors.email ? errors.email : ""}
                  validateStatus={
                    touched.email && errors.email ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"email"}
                        className={"form-field_input_2"}
                        value={values?.email || ""}
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("email", e.target.value);
                        }}
                        placeholder="Enter email address"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Phone number"} required />
                <Form.Item
                  name={"phonenumber"}
                  help={
                    touched.phonenumber && errors.phonenumber
                      ? errors.phonenumber
                      : ""
                  }
                  validateStatus={
                    touched.phonenumber && errors.phonenumber
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <PhoneInput
                        defaultCountry="NG"
                        className={"form-field_input_2"}
                        placeholder="+234 0000 000 0000"
                        value={values?.phonenumber || ""}
                        onChange={(e) => {
                          setFieldValue("phonenumber", e);
                        }}
                        onBlur={(e: any) => {
                          setFieldValue("phonenumber", e.target.value);
                        }}
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Role"} required />
                <Form.Item
                  name={"role"}
                  help={touched.role && errors.role ? errors.role : ""}
                  validateStatus={
                    touched.role && errors.role ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Select
                        placeholder={"Select role"}
                        value={values?.role || null}
                        onBlur={handleBlur}
                        className={"form-field_input_3"}
                        onChange={(e: any) => {
                          setFieldValue("role", e);
                        }}
                        options={[{ value: "User", label: "User" }]}
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
          </div>
          <div className="w-full left-0 right-0 bottom-0 bg-white">
            <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
            <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
              <div className={"col-1 sm:block hidden"}></div>
              <div className={"col-1"}>
                <Button
                  css={
                    "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                  }
                  state={ButtonState.PRIMARY}
                  text={"Cancel"}
                  type={"button"}
                  onClick={cancel}
                />
              </div>
              <div className={"col-1"}>
                <Button
                  css={"w-full !px-0 !rounded-[6px]"}
                  state={ButtonState.PRIMARY}
                  text={`${!user ? "Save details" : "Update details"}`}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validator = yup.object().shape({
  first_name: yup
    .string()
    .min(3, "First name should be minimum of 3 characters")
    .required("First name is required"),
  last_name: yup
    .string()
    .min(3, "Last name should be minimum of 3 characters")
    .required("Last name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phonenumber: yup
    .string()
    .required("Phone number is required")
    .test("is-valid-phone", "Phone number is invalid", (value) => {
      return isValidPhoneNumber(value || "");
    }),
  role: yup.string().required("Role is required"),
});

export { UserForm };
