import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { IEditProductDetails } from "../../model";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import QuicstockSupplier from "../../../../assets/imgs/quicstock-supplier.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";

const EditProductDetails: FC<IEditProductDetails> = ({
  productDetails,
  goBack,
  complete,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const [uom, setUom] = useState<string>("");

  useEffect(() => {
    if (productDetails?.uoms && productDetails.uoms?.length) {
      const u = productDetails?.uoms?.map((u) => u.name)?.join(", ");
      setUom(u);
    }
  }, [productDetails.uoms]);

  return (
    <div className="w-full">
      <div className="w-full drawer-content-height">
        <div className="flex flex-col justify-center items-center">
          <div className="mb-6">
            {productDetails?.imagePreview ? (
              <img
                src={productDetails?.imagePreview}
                alt={"Preview"}
                className={"h-[150px]"}
              />
            ) : (
              <img
                src={QuicstockSupplier}
                alt={"Preview"}
                className={"h-[150px]"}
              />
            )}
          </div>
          <ProductInfo
            label={"Product name"}
            value={productDetails?.name ?? ""}
          />
          <ProductInfo label={"UPC"} value={productDetails?.upc ?? ""} />
          <ProductInfo label={"Brand"} value={productDetails?.brand ?? ""} />
          <ProductInfo
            label={"Category"}
            value={productDetails?.category ?? ""}
          />
          <ProductInfo label={"Weight"} value={productDetails?.weight ?? ""} />
          <ProductInfo label={"Color"} value={productDetails?.color ?? ""} />
          <ProductInfo
            label={"Description"}
            value={productDetails?.description ?? ""}
          />
          <ProductInfo label={"Unit(s)"} value={uom} />
        </div>
      </div>
      <div className="w-full left-0 right-0 bottom-0 bg-white">
        <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
        <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
          <div className={"col-1 sm:block hidden"}></div>
          <div className={"col-1"}>
            <Button
              css={
                "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
              }
              state={ButtonState.PRIMARY}
              text={"Go back"}
              type={"button"}
              onClick={goBack}
            />
          </div>
          <div className={"col-1"}>
            <Button
              css={"w-full !rounded-[6px] !px-0"}
              state={ButtonState.PRIMARY}
              text={"Save"}
              type={"button"}
              onClick={complete}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductInfo: FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <div className="flex justify-between w-full mb-6">
      <div className="w-1/2 text-left text-gray-300">{label}</div>
      <div className="w-1/2 text-right">{value || "-"}</div>
    </div>
  );
};

export { EditProductDetails };
