import React, { useEffect, useState } from "react";
import "./App.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./views/Auth/Login";
import Signup from "./views/Auth/Signup";
import Otp from "./views/Auth/Otp";
import CreateAccount from "./views/Auth/CreateAccount";
import AuthLayout from "./layout/AuthLayout";
import DashboardLayout from "./layout/MainLayout";
import Overview from "./views/Main/Overview";
import Purchases from "./views/Main/Purchases";
import Products from "./views/Main/Products/Products";
import Suppliers from "./views/Main/Suppliers";
import UserManagement from "./views/Main/UserManagement";
import Sales from "./views/Main/Sales";
import Reports from "./views/Main/Reports";
import LocationManagement from "./views/Main/LocationManagement";
import LMBulk from "./views/Main/LocationManagement/LMBulk";
import LMSales from "./views/Main/LocationManagement/LMSales";
import LMOnlineSales from "./views/Main/LocationManagement/LMOnlineSales";
import Alerts from "./views/Main/Alerts";
import Settings from "./views/Main/Settings";
import SingleLMSales from "./views/Main/LocationManagement/SingleLMSales";
import SingleLMOnlineSales from "./views/Main/LocationManagement/SingleLMOnlineSales";
import SingleLMBulk from "./views/Main/LocationManagement/SingleLMBulk";
import ProductDetails from "./views/Main/Products/ProductDetails";
import Preloader from "./components/Preloader";
import NewPurchaseOrder from "./views/Main/Purchases/NewPurchaseOrder";
import ConfirmPurchaseOrder from "./views/Main/Purchases/ConfirmPurchaseOrder";
import Product from "./views/Main/Products";
import CustomProperties from "./views/Main/Products/CustomProperties";
import NewCustomProperty from "./views/Main/Products/NewCustomProperty";
import Units from "./views/Main/Products/Units";

const App = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 1000,
      easing: "ease-in-sine",
      delay: 100,
    });
    document.title = "Quicstock";
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="App">
          <Router>
            <Routes>
              {/* Landing Page */}
              <Route path="/" element={<AuthLayout />}>
                <Route index element={<Navigate to="auth" replace />} />
              </Route>

              {/* Auth Pages */}
              <Route path="auth" element={<AuthLayout />}>
                {/* Default redirect to overview */}
                <Route index element={<Navigate to="login" replace />} />

                {/* Children routes */}
                <Route path="login" element={<Login />} />
                <Route path="sign-up" element={<Signup />} />
                <Route path="otp" element={<Otp />} />
                <Route path="create-account" element={<CreateAccount />} />
              </Route>

              {/* Dashboard Pages */}
              <Route path="dashboard" element={<DashboardLayout />}>
                {/* Default redirect to overview */}
                <Route index element={<Navigate to="overview" replace />} />

                {/* Children routes */}
                <Route path="overview" element={<Overview />} />

                {/* Purchases Pahes */}
                <Route path="purchases" element={<Purchases />} />
                <Route path="purchases/new" element={<NewPurchaseOrder />} />
                <Route
                  path="purchases/confirm/:id"
                  element={<ConfirmPurchaseOrder />}
                />

                {/* Products Pages */}
                <Route path="product" element={<Product />}>
                  {/* Producs */}
                  <Route path="products" element={<Products />} />
                  <Route path="products/:id" element={<ProductDetails />} />
                  {/* Custom properties */}
                  <Route
                    path="custom-properties"
                    element={<CustomProperties />}
                  />
                  <Route
                    path="custom-properties/new"
                    element={<NewCustomProperty />}
                  />
                  {/* Units */}
                  <Route path="units" element={<Units />} />
                </Route>

                <Route path="suppliers" element={<Suppliers />} />
                <Route path="user-management" element={<UserManagement />} />
                <Route path="sales" element={<Sales />} />
                <Route path="reports" element={<Reports />} />
                <Route path="alerts" element={<Alerts />} />
                <Route path="settings" element={<Settings />} />

                {/* Location management Pages */}
                <Route
                  path="location-mamagement"
                  element={<LocationManagement />}
                >
                  {/* Bulk location */}
                  <Route path="bulk" element={<LMBulk />} />
                  <Route path="bulk/:id" element={<SingleLMBulk />} />
                  {/* Sales location */}
                  <Route path="sales" element={<LMSales />} />
                  <Route path="sales/:id" element={<SingleLMSales />} />
                  {/* Onlin sales location */}
                  <Route path="online-sales" element={<LMOnlineSales />} />
                  <Route
                    path="online-sales/:id"
                    element={<SingleLMOnlineSales />}
                  />
                </Route>
              </Route>
            </Routes>
          </Router>
        </div>
      )}
    </>
  );
};

export default App;
