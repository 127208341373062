import React, { useState } from "react";
import "../styles.scss";
import EmptyContent from "../../../components/EmptyContent";
import QuicstockPackage from "../../../assets/imgs/quicstock-package.png";
import { AllLogs } from "./components";
import { UserItemModel } from "../../../model/user.model";

const UserLogsTab = () => {
  const [users] = useState<UserItemModel[]>([
    {
      id: "1",
      email: "",
      first_name: "",
      last_name: "",
      staff_id: "",
      phonenumber: "",
      status: "",
      role: "",
    },
  ]);

  return (
    <div className="w-full h-full">
      <div className="default-page-height w-full">
        {users.length ? (
          <AllLogs />
        ) : (
          <EmptyContent
            label={"You have no logs yet"}
            component={
              <img
                src={QuicstockPackage}
                alt={"QuicstockPackage"}
                className={"h-[200px]"}
              />
            }
            showPrimaryButton={false}
          />
        )}
      </div>
    </div>
  );
};

export default UserLogsTab;
