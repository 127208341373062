import React, { FC } from "react";
import "../../styles.scss";
import { ICreateUser } from "../../model";
import AppStepper from "../../../../components/AppStepper";
import { UserDetails, UserForm } from ".";

const CreateUser: FC<ICreateUser> = ({
  user,
  submit,
  goBack,
  complete,
  cancel,
  activeStep,
}) => {
  return (
    <div className="w-full">
      <AppStepper
        activeStep={activeStep}
        stepOneLabel={"Add details"}
        stepTwoLabel={"Preview"}
      />
      <div className="mt-10">
        <div hidden={activeStep !== 0}>
          <UserForm submit={submit} cancel={cancel} />
        </div>
        <div hidden={activeStep === 0}>
          <UserDetails state={"ADD"} goBack={goBack} complete={complete} user={user} />
        </div>
      </div>
    </div>
  );
};

export { CreateUser };
