import React, { FC, useEffect, useState } from "react";
import "../styles.scss";
import EmptyContent from "../../../components/EmptyContent";
import QuicstockPackage from "../../../assets/imgs/quicstock-package.png";
import { IUserManagementTab } from "../model";
import AppDrawer from "../../../components/AppDrawer";
import { AllUsers, CreateUser, UpdateUser, ViewDetails } from "./components";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import Confirm from "../../../components/Confirm";
import { UserItemModel, UserResponseModel } from "../../../model/user.model";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { normalizePhoneNumber } from "../../../utils/constants";
import {
  AddUser,
  DeleteUser,
  DisableUser,
  EditUser,
  EnableUser,
  FetchUsers,
} from "../../../redux/actions/users.action";
import { ApiPaginationModel } from "../../../model/base.model";

const UserManagementTab: FC<IUserManagementTab> = ({
  openNewUserDrawer,
  setOpenNewUserDrawer,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [openEditUserDrawer, setOpenEditUserDrawer] = useState(false);
  const [openViewUserDrawer, setOpenViewUserDrawer] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [paginationData, setPaginationData] = useState<ApiPaginationModel>({
    itemsPerPage: 10,
    page: 1,
  });
  const [users] = useState<UserItemModel[]>([
    {
      id: "1",
      email: "",
      first_name: "",
      last_name: "",
      staff_id: "",
      phonenumber: "",
      status: "",
      role: "",
    },
  ]);
  const [user, setUser] = useState<UserItemModel>({
    email: "",
    first_name: "",
    last_name: "",
    staff_id: "",
    phonenumber: "",
    status: "",
    role: "",
  });

  const toggleNewUserDrawer = () => {
    setOpenNewUserDrawer(!openNewUserDrawer);
  };

  const toggleEditUserDrawer = () => {
    setOpenEditUserDrawer(!openEditUserDrawer);
  };

  const toggleViewUserDrawer = () => {
    setOpenViewUserDrawer(!openViewUserDrawer);
  };

  const handleSubmit = (values: UserItemModel) => {
    setUser(values);
    setActiveStep(1);
  };

  const handleUpdateUser = (user: UserItemModel) => {
    setUser(user);
    setActiveStep(0);
    toggleEditUserDrawer();
  };

  const handleEnableUser = (user: UserItemModel) => {
    setOpen(true);
    setUser(user);
  };

  const handleDisableUser = (user: UserItemModel) => {
    setOpen(true);
    setUser(user);
  };

  const handleDeleteUser = (userId: string) => {
    deleteUser(userId);
  };

  const handleComplete = (state: "ADD" | "UPDATE") => {
    if (state === "ADD") {
      addUser();
    } else {
      editUser();
    }
  };

  const handleUserView = (user: UserItemModel) => {
    setUser(user);
    toggleViewUserDrawer();
  };

  const addUser = () => {
    dispatch(
      AddUser({
        ...user,
        phonenumber: normalizePhoneNumber(user.phonenumber ?? ""),
      })
    )
      .then(async (res: UserResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="User successfully added"
            message={res?.message}
          />,
          true
        );
        toggleNewUserDrawer();
        setActiveStep(0);
        dispatch(FetchUsers(paginationData));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const editUser = () => {
    dispatch(
      EditUser({
        ...user,
        phonenumber: normalizePhoneNumber(user.phonenumber ?? ""),
      })
    )
      .then(async (res: UserResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="User successfully updated"
            message={res?.message}
          />,
          true
        );
        toggleEditUserDrawer();
        setActiveStep(0);
        dispatch(FetchUsers(paginationData));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const enableUser = () => {
    dispatch(EnableUser(user?.id ?? ""))
      .then(async (res: UserResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title={"User enabled successfully"}
            message={res?.message}
          />,
          true
        );
        setOpen(false);
        dispatch(FetchUsers(paginationData));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const disableUser = () => {
    dispatch(DisableUser(user?.id ?? ""))
      .then(async (res: UserResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title={"User disabled successfully"}
            message={res?.message}
          />,
          true
        );
        setOpen(false);
        dispatch(FetchUsers(paginationData));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const deleteUser = (userId: string) => {
    dispatch(DeleteUser(userId))
      .then(async (res: UserResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="User successfully deleted"
            message={res?.message}
          />,
          true
        );
        dispatch(FetchUsers(paginationData));
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  useEffect(() => {
    dispatch(FetchUsers(paginationData));
  }, [dispatch, paginationData]);

  return (
    <div className="w-full h-full">
      <div className="default-page-height w-full">
        {users.length ? (
          <AllUsers
            viewUser={handleUserView}
            updateUser={handleUpdateUser}
            disableUser={handleDisableUser}
            enableUser={handleEnableUser}
            deleteUser={handleDeleteUser}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        ) : (
          <EmptyContent
            label={"You have no users yet"}
            component={
              <img
                src={QuicstockPackage}
                alt={"QuicstockPackage"}
                className={"h-[200px]"}
              />
            }
            showPrimaryButton={true}
            primaryButtonLabel={"Create"}
            handlePrimaryButtonClick={() => {
              setActiveStep(0);
              toggleNewUserDrawer();
            }}
          />
        )}
      </div>

      {/* Create user */}
      <AppDrawer
        open={openNewUserDrawer}
        toggle={() => {
          setActiveStep(0);
          toggleNewUserDrawer();
        }}
        title={"Add user"}
        subtitle={"Add a new user"}
      >
        <CreateUser
          user={user}
          submit={handleSubmit}
          goBack={() => setActiveStep(0)}
          complete={handleComplete}
          cancel={toggleNewUserDrawer}
          activeStep={activeStep}
        />
      </AppDrawer>
      {/* Create user */}

      {/* Update user */}
      <AppDrawer
        open={openEditUserDrawer}
        toggle={toggleEditUserDrawer}
        title={"Edit user's details"}
        subtitle={`${user.first_name} ${user.last_name}`}
      >
        <UpdateUser
          submit={handleSubmit}
          goBack={() => setActiveStep(0)}
          complete={handleComplete}
          cancel={toggleEditUserDrawer}
          activeStep={activeStep}
          user={user}
        />
      </AppDrawer>
      {/* Update user */}

      {/* View user details */}
      <AppDrawer
        open={openViewUserDrawer}
        toggle={toggleViewUserDrawer}
        title={`${user.first_name} ${user.last_name}`}
        subtitle={"Details"}
      >
        <ViewDetails user={user} />
      </AppDrawer>
      {/* View user details */}

      <Confirm
        title={user.status === "active" ? "Disable User" : "Enable User"}
        confirmButtonText={user.status === "active" ? "Disable" : "Enable"}
        open={open}
        cancel={() => setOpen(false)}
        confirm={user.status === "active" ? disableUser : enableUser}
      />
    </div>
  );
};

export default UserManagementTab;
