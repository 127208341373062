import React, { FC } from "react";
import "../../styles.scss";
import { IEditProduct } from "../../model";
import AppStepper from "../../../../components/AppStepper";
import { EditProductForm } from "./EditProductForm";
import { EditProductDetails } from "./EditProductDetails";

const EditProduct: FC<IEditProduct> = ({
  submit,
  goBack,
  complete,
  cancel,
  activeStep,
  product,
  productDetails,
}) => {
  return (
    <div className="w-full">
      <AppStepper
        activeStep={activeStep}
        stepOneLabel={"Edit Product"}
        stepTwoLabel={"Preview"}
      />
      <div className="mt-10">
        <div hidden={activeStep !== 0}>
          <EditProductForm submit={submit} cancel={cancel} product={product} />
        </div>
        <div hidden={activeStep === 0}>
          <EditProductDetails
            productDetails={productDetails}
            goBack={goBack}
            complete={complete}
          />
        </div>
      </div>
    </div>
  );
};

export { EditProduct };
