import React, { useEffect, useState } from "react";
import "../styles.scss";
import { LeftHeaderType } from "../../../components/enum";
import PageHeader from "../../../components/PageHeader";
import EmptyContent from "../../../components/EmptyContent";
import QuicstockUnit from "../../../assets/imgs/quicstock-unit.png";
import { AddUnit, AllUnits, EditUnit, ViewUnit } from "./components";
import AppDrawer from "../../../components/AppDrawer";
import openNotification from "../../../components/Notification/notification";
import NotificationMessage from "../../../components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  CreateUnit,
  DeleteUnit,
  FetchUnits,
  UpdateUnit,
} from "../../../redux/actions/products.action";
import { RootState } from "../../../redux/reducers";
import {
  ProductResponseModel,
  UnitItemModel,
} from "../../../model/product.model";
import Loader from "../../../components/Loader";
import Confirm from "../../../components/Confirm";

const Units = () => {
  const dispatch: AppDispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const [activeStep, setActiveStep] = useState<number>(0);
  const uoms = useSelector((state: RootState) => state.products.uoms);
  const [openNewUnitDrawer, setOpenNewUnitDrawer] = useState(false);
  const [openViewUnitDrawer, setOpenViewUnitDrawer] = useState(false);
  const [openEditUnitDrawer, setOpenEditUnitDrawer] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [unit, setUnit] = useState<UnitItemModel>({ name: "", factor: "" });

  const handleClick = () => {
    toggleNewUnitDrawer();
  };

  useEffect(() => {
    console.log(search);
  }, [search]);

  const toggleNewUnitDrawer = () => {
    setOpenNewUnitDrawer(!openNewUnitDrawer);
  };

  const toggleViewUnitDrawer = () => {
    setOpenViewUnitDrawer(!openViewUnitDrawer);
  };

  const toggleEditUnitDrawer = () => {
    setOpenEditUnitDrawer(!openEditUnitDrawer);
  };

  const handleOpenEditModal = () => {
    toggleViewUnitDrawer();
    toggleEditUnitDrawer();
  };

  const handleView = (unit: UnitItemModel) => {
    setUnit(unit);
    toggleViewUnitDrawer();
  };

  const handleUpdate = (unit: UnitItemModel) => {
    setUnit(unit);
    toggleEditUnitDrawer();
  };

  const handleDelete = (unit: UnitItemModel) => {
    setUnit(unit);
    setOpen(true);
  };

  const handleSubmit = (values: UnitItemModel, state: "ADD" | "UPDATE") => {
    setUnit({ ...values, factor: parseInt(values?.factor as string) });
    if (state === "ADD") {
      setActiveStep(1);
    } else {
      updateUnit();
    }
  };

  const handleComplete = () => {
    createUnit();
  };

  const createUnit = () => {
    dispatch(CreateUnit(unit))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Unit of measurement created"
            message={res?.message}
          />,
          true
        );
        toggleNewUnitDrawer();
        setActiveStep(0);
        dispatch(FetchUnits());
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const updateUnit = () => {
    dispatch(UpdateUnit(unit?.id as any, unit))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Unit of measurement updated"
            message={res?.message}
          />,
          true
        );
        toggleEditUnitDrawer();
        setActiveStep(0);
        dispatch(FetchUnits());
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  const deleteUnit = () => {
    dispatch(DeleteUnit(unit?.id as any))
      .then(async (res: ProductResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Unit of measurement deleted"
            message={res?.message}
          />,
          true
        );
        dispatch(FetchUnits());
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  useEffect(() => {
    dispatch(FetchUnits());
  }, [dispatch]);

  return (
    <div className="w-full h-full">
      <PageHeader
        leftHeaderType={LeftHeaderType.CUSTOM}
        showSearchField={true}
        title={"Unit of measurements"}
        subtitle={"Unit of measurements"}
        buttonText={"New unit"}
        getSearchValue={setSearch}
        handleClick={handleClick}
      />
      <div className="default-page-height w-full">
        <Loader isLoading={isLoading} />
        {uoms && uoms?.length ? (
          <AllUnits
            viewUnit={handleView}
            updateUnit={handleUpdate}
            deleteUnit={handleDelete}
          />
        ) : (
          <EmptyContent
            label={"No unit created here yet!"}
            note={"Why not add one or two?"}
            component={
              <img
                src={QuicstockUnit}
                alt={"QuicstockUnit"}
                className={"h-[200px]"}
              />
            }
            showPrimaryButton={true}
            primaryButtonLabel={"Add new units of measurement"}
            handlePrimaryButtonClick={() => {
              setActiveStep(0);
              toggleNewUnitDrawer();
            }}
          />
        )}
      </div>

      {/* Add unit */}
      <AppDrawer
        open={openNewUnitDrawer}
        toggle={() => {
          setActiveStep(0);
          toggleNewUnitDrawer();
        }}
        title={"Add unit"}
        subtitle={"Add a new unit"}
      >
        <AddUnit
          submit={handleSubmit}
          goBack={() => setActiveStep(0)}
          complete={handleComplete}
          cancel={toggleNewUnitDrawer}
          activeStep={activeStep}
          unit={unit}
        />
      </AppDrawer>
      {/* Add unit */}

      {/* View unit */}
      <AppDrawer
        open={openViewUnitDrawer}
        toggle={toggleViewUnitDrawer}
        title={"View unit details"}
        subtitle={unit.name}
      >
        <ViewUnit
          cancel={toggleViewUnitDrawer}
          edit={handleOpenEditModal}
          unit={unit}
        />
      </AppDrawer>
      {/* View unit */}

      {/* Edit unit */}
      <AppDrawer
        open={openEditUnitDrawer}
        toggle={toggleEditUnitDrawer}
        title={"Edit unit details"}
        subtitle={unit.name}
      >
        <EditUnit
          submit={handleSubmit}
          cancel={toggleEditUnitDrawer}
          unit={unit}
        />
      </AppDrawer>
      {/* Edit unit */}

      <Confirm
        title="Delete this unit"
        confirmButtonText="Delete"
        message="Deleting this unit is permanent and cannot be reversed. Still want to proceed?"
        open={open}
        cancel={() => setOpen(false)}
        confirm={deleteUnit}
      />
    </div>
  );
};

export default Units;
