import React, { FC } from "react";
import "../../styles.scss";
import { IEditUnit } from "../../model";
import { UnitForm } from "./UnitForm";

const EditUnit: FC<IEditUnit> = ({ submit, cancel, unit }) => {
  return (
    <div className="w-full mt-10">
      <UnitForm
        submit={submit}
        cancel={cancel}
        unit={unit}
        state={"UPDATE"}
      />
    </div>
  );
};

export { EditUnit };
