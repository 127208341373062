import { ThunkAction } from "redux-thunk";
import {
  addUser,
  getUsers,
  editUser,
  enableUser,
  disableUser,
  deleteUser,
} from "../_api/index";
import { RootState } from "../reducers";
import { Action } from "redux";
import { UserItemModel } from "../../model/user.model";
import { ApiPaginationModel } from "../../model/base.model";

export const Types = {
  ADD_USER_START: "ADD_USER_START",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_END: "ADD_USER_END",

  FETCH_USERS_START: "FETCH_USERS_START",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  FETCH_USERS_END: "FETCH_USERS_END",

  EDIT_USER_START: "EDIT_USER_START",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_END: "EDIT_USER_END",

  ENABLE_USER_START: "ENABLE_USER_START",
  ENABLE_USER_SUCCESS: "ENABLE_USER_SUCCESS",
  ENABLE_USER_END: "ENABLE_USER_END",

  DISABLE_USER_START: "DISABLE_USER_START",
  DISABLE_USER_SUCCESS: "DISABLE_USER_SUCCESS",
  DISABLE_USER_END: "DISABLE_USER_END",

  DELETE_USER_START: "DELETE_USER_START",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_END: "DELETE_USER_END",
};

export const AddUser = (
  req: UserItemModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.ADD_USER_START });
    return new Promise((resolve, reject) => {
      addUser(req)
        .then(({ data }) => {
          dispatch({ type: Types.ADD_USER_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.ADD_USER_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const FetchUsers = (
  params: ApiPaginationModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_USERS_START });
    return new Promise((resolve, reject) => {
      getUsers(params)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_USERS_SUCCESS,
            users: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_USERS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const EditUser = (
  req: UserItemModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.EDIT_USER_START });
    return new Promise((resolve, reject) => {
      editUser(req)
        .then(({ data }) => {
          dispatch({ type: Types.EDIT_USER_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.EDIT_USER_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const EnableUser = (
  userId: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.ENABLE_USER_START });
    return new Promise((resolve, reject) => {
      enableUser(userId)
        .then(({ data }) => {
          dispatch({ type: Types.ENABLE_USER_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.ENABLE_USER_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const DisableUser = (
  userId: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.DISABLE_USER_START });
    return new Promise((resolve, reject) => {
      disableUser(userId)
        .then(({ data }) => {
          dispatch({ type: Types.DISABLE_USER_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.DISABLE_USER_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const DeleteUser = (
  userId: string
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.DELETE_USER_START });
    return new Promise((resolve, reject) => {
      deleteUser(userId)
        .then(({ data }) => {
          dispatch({ type: Types.DELETE_USER_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.DELETE_USER_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};
