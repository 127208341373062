import { UserResponseItemModel } from "../../model/user.model";
import { Types } from "../actions/users.action";

interface UserState {
  isUserActionLoading: boolean;
  message?: string;
  users: UserResponseItemModel;
}

interface UserAction {
  type: string;
  message?: string;
  users: UserResponseItemModel;
}

const INITIAL_STATE: UserState = {
  isUserActionLoading: false,
  users: null as any,
};

export const userReducer = (state = INITIAL_STATE, action: UserAction) => {
  switch (action.type) {
    case Types.ADD_USER_START:
    case Types.FETCH_USERS_START:
    case Types.EDIT_USER_START:
    case Types.ENABLE_USER_START:
    case Types.DISABLE_USER_START:
    case Types.DELETE_USER_START:
      return { ...state, isUserActionLoading: true };
    case Types.ADD_USER_SUCCESS:
    case Types.EDIT_USER_SUCCESS:
    case Types.ENABLE_USER_SUCCESS:
    case Types.DISABLE_USER_SUCCESS:
    case Types.DELETE_USER_SUCCESS:
      return {
        ...state,
        isUserActionLoading: false,
      };
    case Types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        isUserActionLoading: false,
        users: action.users,
      };
    case Types.ADD_USER_END:
    case Types.FETCH_USERS_END:
    case Types.EDIT_USER_END:
    case Types.ENABLE_USER_END:
    case Types.DISABLE_USER_END:
    case Types.DELETE_USER_END:
      return {
        ...state,
        isUserActionLoading: false,
        message: action.message,
      };
    default:
      return state;
  }
};
