import { Popover, Table } from "antd";
import React, { FC, useState } from "react";
import "../../styles.scss";
import { IAllUnits } from "../../model";
import { ReactComponent as QuicstockMore } from "../../../../assets/svgs/quicstock-more.svg";
import { ReactComponent as QuicstockEye } from "../../../../assets/svgs/quicstock-eye.svg";
import { ReactComponent as QuicstockUploadDoc } from "../../../../assets/svgs/quicstock-upload-doc.svg";
import { ReactComponent as QuicstockTrash } from "../../../../assets/svgs/quicstock-trash.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { UnitItemModel } from "../../../../model/product.model";

const ACTION_STYLE =
  "text-sm-regular mb-4 p-2 gap-x-3 flex items-center cursor-pointer";

const AllUnits: FC<IAllUnits> = ({ viewUnit, updateUnit, deleteUnit }) => {
  const isLoading = useSelector(
    (state: RootState) => state.suppliers.isSupplierActionLoading
  );
  const uoms = useSelector((state: RootState) => state.products.uoms);
  const [open, setOpen] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [unit, setUnit] = useState<UnitItemModel | undefined>(undefined);

  const handleOpenChange = (newOpen: boolean, record: any) => {
    setOpen(newOpen);
    setCurrentRow(record?.key);
    const { uom } = record;
    setUnit({
      name: uom?.name,
      factor: uom?.factor,
      assigned_products: uom?.assigned_products,
      created_at: uom?.created_at,
      id: uom?.id,
    } as any);
  };

  const handleMore = (option: "VIEW" | "UPDATE" | "DELETE") => {
    if (option === "VIEW") viewUnit(unit as UnitItemModel);
    if (option === "UPDATE") updateUnit(unit as UnitItemModel);
    if (option === "DELETE") deleteUnit(unit as UnitItemModel);
    setOpen(false);
  };

  const columns = [
    {
      title: "Unit name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Conversion",
      dataIndex: "factor",
      key: "factor",
    },
    {
      title: "Assigned products",
      dataIndex: "assigned_products",
      key: "assigned_products",
    },
    {
      title: <QuicstockMore />,
      key: "actions",
      render: (record: any) => (
        <Popover
          content={content}
          trigger="click"
          open={open && currentRow === record?.key}
          placement="bottomLeft"
          onOpenChange={(e) => {
            handleOpenChange(e, record);
          }}
        >
          <div className="cursor-pointer">
            <QuicstockMore />
          </div>
        </Popover>
      ),
    },
  ];

  const content = (
    <div className="w-[210px] table-menu">
      <div className={ACTION_STYLE} onClick={() => handleMore("VIEW")}>
        <QuicstockEye />
        View more
      </div>
      <div className={ACTION_STYLE} onClick={() => handleMore("UPDATE")}>
        <QuicstockUploadDoc />
        Update
      </div>
      <div
        className={`${ACTION_STYLE} !mb-0`}
        onClick={() => handleMore("DELETE")}
      >
        <QuicstockTrash />
        Delete
      </div>
    </div>
  );

  const data =
    uoms &&
    uoms?.length > 0 &&
    uoms?.map((uom, index) => {
      return {
        key: index,
        name: uom.name ?? "---",
        factor: uom?.factor ? <span>{uom?.factor} items</span> : "---",
        assigned_products: <span>{uom?.assigned_products} products</span>,
        id: uom?.id,
        uom,
      };
    });

  return (
    <>
      <div className="w-full bg-white filter-table pb-4 overflow-hidden mt-10">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={data as any}
          size={"small"}
          className={"d-table"}
          pagination={false}
        />
      </div>
    </>
  );
};

export { AllUnits };
