import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { IUnitForm } from "../../model";
import { Field, Formik } from "formik";
import { Form, Input } from "antd";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import FieldName from "../../../../components/FieldName";
import "react-phone-number-input/style.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { UnitItemModel } from "../../../../model/product.model";

const UnitForm: FC<IUnitForm> = ({ submit, cancel, unit, state }) => {
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const [values, setValues] = useState<UnitItemModel>({
    name: "",
    factor: "",
  });
  const [form] = Form.useForm();
  const handleOnSubmit = (values: any, { resetForm }: any) => {
    if (unit) {
      submit(values, "UPDATE");
    } else {
      submit(values, "ADD");
    }
  };

  useEffect(() => {
    if (unit) {
      setValues({
        ...unit,
      });
    }
  }, [unit]);

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div
            className={`w-full ${state === "ADD" ? "drawer-content-height" : "drawer-content-height-no-step"}`}
          >
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Unit name"} required={true} />
                <Form.Item
                  name={"name"}
                  help={touched.name && errors.name ? errors.name : ""}
                  validateStatus={
                    touched.name && errors.name ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        value={values?.name}
                        onChange={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        placeholder="Enter unit name"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName
                  name={
                    values?.name
                      ? `Conversion factor - 1 ${values?.name?.toLowerCase()} is for how many items?`
                      : "Conversion factor"
                  }
                />
                <Form.Item
                  name={"factor"}
                  help={touched.factor && errors.factor ? errors.factor : ""}
                  validateStatus={
                    touched.factor && errors.factor ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Input
                        type={"number"}
                        className={"form-field_input_2"}
                        value={values?.factor || ""}
                        onChange={(e) => {
                          setFieldValue("factor", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("factor", e.target.value);
                        }}
                        placeholder="Enter conversion factor"
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
          </div>
          <div className="w-full left-0 right-0 bottom-0 bg-white">
            <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
            <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
              <div className={"col-1 sm:block hidden"}></div>
              <div className={"col-1"}>
                <Button
                  css={
                    "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                  }
                  state={ButtonState.PRIMARY}
                  text={`${!unit ? "Cancel" : "Go back"}`}
                  type={"button"}
                  onClick={cancel}
                />
              </div>
              <div className={"col-1"}>
                <Button
                  css={"w-full !px-0 !rounded-[6px]"}
                  state={ButtonState.PRIMARY}
                  text={`${!unit ? "Preview unit" : "Save changes"}`}
                  type={"submit"}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validator = yup.object().shape({
  name: yup
    .string()
    .min(3, "Unit name should be minimum of 3 characters")
    .required("Unit name is required"),
  factor: yup
    .number()
    .min(1, "Conversion factor should be at least 1")
    .required("Conversion factor is required"),
});

export { UnitForm };
