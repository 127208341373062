import React, { FC, useEffect } from "react";
import "../../styles.scss";
import { ITransferLocationProductData } from "../../model";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import QuicstockNoImage from "../../../../assets/imgs/quicstock-no-image.png";
import { formatCurrency, NAIRA } from "../../../../utils/constants";
import { AppDispatch } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchProduct,
  FetchProductPOs,
} from "../../../../redux/actions/products.action";
import { RootState } from "../../../../redux/reducers";

const TransferLocationProductData: FC<ITransferLocationProductData> = ({
  transfer,
  remove,
  viewAllPurchaseOrders,
  product,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const productDetails = useSelector(
    (state: RootState) => state.products.product
  );
  const productPos = useSelector(
    (state: RootState) => state.products.productPos
  );

  useEffect(() => {
    if (product?.product_id) {
      dispatch(FetchProduct(product?.product_id));
      dispatch(FetchProductPOs(product?.product_id));
    }
  }, [dispatch, product]);

  return (
    <div className="w-full mt-10">
      <div className="w-full drawer-content-height-no-step">
        <div className="flex w-full flex-col items-center justify-center">
          <img
            src={
              productDetails?.default_properties?.image_url
                ? productDetails?.default_properties?.image_url
                : QuicstockNoImage
            }
            alt={
              productDetails?.default_properties?.image_url
                ? productDetails?.default_properties?.name
                : "QuicstockProductImageTwo"
            }
            className={"xl:h-[64px] h-[60px] rounded-full"}
          />
          <p className="text-sm-headline mt-4">{product?.product_name}</p>
        </div>
        <div className="my-4 border-[0.5px] border-[#EFEFEF]"></div>
        <TransferLocationProductDetails
          label={"Quantity"}
          value={product?.quantity?.toString() ?? ""}
        />
        <TransferLocationProductDetails label={"Color"} value={"---"} />
        <TransferLocationProductDetails label={"Brand"} value={"---"} />
        <TransferLocationProductDetails
          label={"Cost price"}
          value={`${NAIRA}${formatCurrency(product?.retail_price?.toString() ?? "")}`}
        />
        <TransferLocationProductDetails
          label={"Category"}
          value={productDetails?.default_properties?.category ?? "-"}
        />
        <TransferLocationProductDetails
          label={"Weight"}
          value={`${productDetails?.default_properties?.weight ?? "-"}g`}
        />
        <TransferLocationProductDetails
          label={"Description"}
          value={productDetails?.default_properties?.description ?? "-"}
        />
        <TransferLocationProductDetails
          label={"Unit of measurement"}
          value={
            productDetails?.uom_details?.map((u) => u.name)?.join(", ") ?? "-"
          }
        />
        <div className="my-4 border-[0.5px] border-[#EFEFEF]"></div>
        <p className="font-medium text-sm-headline mb-4">Custom properties</p>
        {productDetails?.custom_properties &&
        productDetails?.custom_properties?.length > 0 ? (
          productDetails?.custom_properties?.map((cp, index) => (
            <React.Fragment key={index + "cp"}>
              {cp?.is_global ? (
                <TransferLocationProductDetails
                  label={cp?.property_name ?? "-"}
                  value={cp?.value ?? "-"}
                  badge={"Global"}
                />
              ) : (
                <div className="p-3 my-4 border-[0.5px] border-[#EFEFEF] rounded-[10px]">
                  <TransferLocationProductDetails
                    label={"Quantity"}
                    value={"5,657"}
                  />
                  <div className="flex flex-wrap gap-x-2">
                    <Order orderNo={"Order #1234"} />
                    <Order orderNo={"Order #1234"} />
                    <Order orderNo={"Order #1234"} />
                    <Order orderNo={"Order #1234"} />
                    <Order orderNo={"Order #1234"} />
                  </div>
                </div>
              )}
            </React.Fragment>
          ))
        ) : (
          <p className="text-labels">No custom properties</p>
        )}
        <div className="my-4 border-[0.5px] border-[#EFEFEF]"></div>
        <div className="flex w-full items-center justify-between">
          <p className="font-medium text-sm-headline">Purchase orders</p>
          {productPos?.length ? (
            <button
              onClick={viewAllPurchaseOrders}
              className="text-primary-500 bg-primary-200 rounded-[24px] py-1 px-3 text-labels"
            >
              View all
            </button>
          ) : (
            <></>
          )}
        </div>
        {!productPos?.length && (
          <p className="text-labels">No purchase order</p>
        )}
      </div>
      <div className="w-full left-0 right-0 bottom-0 bg-white">
        <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
        <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
          <div className={"col-1 sm:block hidden"}></div>
          <div className={"col-1"}>
            <Button
              css={
                "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
              }
              state={ButtonState.PRIMARY}
              text={"Remove"}
              type={"button"}
              onClick={remove}
            />
          </div>
          <div className={"col-1"}>
            <Button
              css={"w-full !rounded-[6px]"}
              state={ButtonState.PRIMARY}
              text={"Transfer"}
              type={"button"}
              onClick={transfer}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const TransferLocationProductDetails: FC<{
  label: string;
  value: string;
  badge?: string;
  className?: string;
}> = ({ label, value, badge, className }) => {
  return (
    <div
      className={`flex justify-between w-full mb-6 xl:text-sm-headline text-labels ${className}`}
    >
      <div className="w-1/2 flex items-center gap-x-4">
        <div className="text-left">{label}</div>
        {badge && (
          <div className="text-secondary-500 bg-secondary-100 rounded-[32px] py-1 px-3 xl:text-sm-regular text-labels font-medium">
            {badge}
          </div>
        )}
      </div>
      <div className="w-1/2 text-right">{value || "-"}</div>
    </div>
  );
};

const Order: FC<{
  orderNo: string;
}> = ({ orderNo }) => {
  return (
    <div className="text-primary-500 bg-primary-200 rounded-[32px] py-1 px-3 text-sm-regular font-medium mb-2">
      {orderNo}
    </div>
  );
};

export { TransferLocationProductData };
