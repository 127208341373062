import React, { FC } from "react";
import "../../styles.scss";
import { IPurchaseItem } from "../../model";
import { formatCurrency, NAIRA } from "../../../../utils/constants";
import { ReactComponent as QuicstockSeparator } from "../../../../assets/svgs/quicstock-separator.svg";

const PurchaseItem: FC<IPurchaseItem> = ({
  image,
  name,
  unit_price = 0,
  total = 0,
  quantity = 0,
  locations,
  uom,
}) => {
  return (
    <div className={`w-full p-4 bg-white rounded-[10px]`}>
      <div className="flex w-full items-center justify-between ">
        <div className="flex gap-y-4 flex-col">
          <div className="flex items-center gap-x-2">
            <img
              src={image}
              alt={name}
              className="w-[30px] h-[30px] rounded-full"
            />
            <p className="sm:text-sm-headline text-labels">{name}</p>
          </div>
          <p className="sm:text-sm-headline text-labels text-gray-300">
            Unit price:{" "}
            <span className="font-medium text-black">
              {NAIRA}
              {formatCurrency(unit_price.toString())}
            </span>
          </p>
        </div>
        <div className="flex gap-y-4 flex-col text-right">
          <p className="sm:text-sm-headline text-labels text-gray-300">
            Quantity:{" "}
            <span className="font-medium text-black">{quantity} Pieces</span>
          </p>
          <p className="sm:text-sm-headline text-labels text-gray-300">
            Total price:{" "}
            <span className="font-medium text-black">
              {NAIRA}
              {formatCurrency(total.toString())}
            </span>
          </p>
        </div>
      </div>
      {uom && uom?.name ? (
        <div className="mt-2">
          <p className="text-labels text-gray-300">
            Units:{" "}
            <span className="text-black">
              {quantity / (uom?.factor as number)} {uom?.name}
            </span>
          </p>
        </div>
      ) : (
        <div className="mt-2">
          <p className="text-labels text-gray-300">
            Units: <span className="text-black">{quantity} Pieces</span>
          </p>
        </div>
      )}
      {(locations?.length ?? 0) > 0 && (
        <div className="my-2 border-[0.5px] border-[#EFEFEF]"></div>
      )}
      {(locations?.length ?? 0) > 0 && (
        <div className="grid sm:grid-cols-12 grid-cols-1 gap-x-2">
          <p className="sm:col-span-2 col text-gray-300 sm:text-sm-regular text-labels">
            Location(s):
          </p>
          <div className="sm:col-span-10 col flex flex-wrap gap-x-2 items-center sm:text-sm-regular text-labels sm:mt-0 mt-2">
            {locations?.map((l, index) => (
              <React.Fragment key={l?.id}>
                <p>
                  {l?.name} - (
                  {uom && uom?.factor
                    ? (l?.quantity as number) / (uom?.factor as number)
                    : l?.quantity}{" "}
                  {uom?.name ?? "Pieces"})
                </p>
                {index < locations.length - 1 && <QuicstockSeparator />}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { PurchaseItem };
