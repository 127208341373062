import React, { FC, useEffect, useRef, useState } from "react";
import "../../styles.scss";
import { IEditProductForm } from "../../model";
import { Field, Formik, FormikErrors, FormikTouched } from "formik";
import { Checkbox, Collapse, CollapseProps, Form, Input } from "antd";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import { ButtonState } from "../../../../components/enum";
import Button from "../../../../components/Button";
import FieldName from "../../../../components/FieldName";
import "react-phone-number-input/style.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import QuicstockNoImage from "../../../../assets/imgs/quicstock-no-image.png";
import { ReactComponent as QuicstockTrash } from "../../../../assets/svgs/quicstock-trash.svg";
import { ReactComponent as QuicstockEditBlank } from "../../../../assets/svgs/quicstock-edit-blank.svg";
import { RightOutlined } from "@ant-design/icons";
import {
  EditProductModel,
  UnitItemModel,
} from "../../../../model/product.model";
import { ApiDropdownModel } from "../../../../model/base.model";
const { TextArea } = Input;

const EditProductForm: FC<IEditProductForm> = ({ submit, cancel, product }) => {
  const isLoading = useSelector(
    (state: RootState) => state.suppliers.isSupplierActionLoading
  );
  const uoms = useSelector((state: RootState) => state.products.uoms);
  const [options, setOptions] = useState<ApiDropdownModel[]>([]);
  const [localUoms, setLocalUoms] = useState<UnitItemModel[]>([]);
  const [values, setValues] = useState<EditProductModel>({
    name: "",
    upc: "",
    brand: "",
    category: "",
    weight: "",
    color: "",
    description: "",
    uom: [],
    uoms: [],
  });
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isImageDeleted, setIsImageDeleted] = useState<boolean>(false);
  const [form] = Form.useForm();
  const handleOnSubmit = (values: any, { resetForm }: any) => {
    values = {
      ...values,
      uoms: localUoms,
      imageFile,
      imagePreview: imagePreview
        ? imagePreview
        : (product?.default_properties?.image_url ?? null),
      isImageDeleted,
    };
    submit(values);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageFile(file);

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
      setIsImageDeleted(false);
    }
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleDeleteImage = () => {
    setImageFile(null);
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setIsImageDeleted(true);
  };

  useEffect(() => {
    if (product) {
      setValues({
        name: product?.default_properties?.name ?? "",
        upc: product?.default_properties?.upc ?? "",
        brand: product?.default_properties?.brand ?? "",
        category: product?.default_properties?.category ?? "",
        weight: product?.default_properties?.weight ?? "",
        color: product?.default_properties?.color ?? "",
        description: product?.default_properties?.description ?? "",
        uom: product?.uom_details?.map((u) => u?.id ?? "") ?? [],
        uoms: product?.uom_details,
      });
      setLocalUoms(product?.uom_details ?? []);
    }
  }, [product]);

  useEffect(() => {
    if (uoms && uoms.length) {
      const suggestions: ApiDropdownModel[] | any[] = uoms.map((uom) => ({
        value: uom.id,
        label: uom.name,
        data: uom,
      }));
      setOptions(suggestions);
    }
  }, [uoms]);

  const updateUoms = (uomIds: string[]) => {
    const _uoms: UnitItemModel[] = uomIds
      .map((id) => uoms.find((u) => u.id === id))
      .filter((u): u is UnitItemModel => !!u);
    setLocalUoms(_uoms);
  };

  const hasError = (
    touched: FormikTouched<EditProductModel>,
    errors: FormikErrors<EditProductModel>
  ): boolean => {
    return (touched.name && errors.name) ||
      (touched.brand && errors.brand) ||
      (touched.category && errors.category) ||
      (touched.color && errors.color) ||
      (touched.description && errors.description) ||
      (touched.upc && errors.upc) ||
      (touched.weight && errors.weight)
      ? true
      : false;
  };

  const getProductDetailsItems = (
    touched: FormikTouched<EditProductModel>,
    errors: FormikErrors<EditProductModel>,
    values: EditProductModel
  ): CollapseProps["items"] => [
    {
      key: "1",
      label: "Product details",
      children: (
        <div className="w-full flex flex-col mt-4 p-3 border border-dark-200 rounded-[10px]">
          <FieldContainer iconPlacementCss={"iconCss"}>
            <FieldName name={"Product name"} required />
            <Form.Item
              name={"name"}
              help={touched.name && errors.name ? errors.name : ""}
              validateStatus={touched.name && errors.name ? "error" : "success"}
            >
              <Field>
                {({ form: { setFieldValue } }: any) => (
                  <Input
                    type={"text"}
                    className={"form-field_input_2"}
                    value={values?.name}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                    onBlur={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                    placeholder="Enter product name"
                  />
                )}
              </Field>
            </Form.Item>
          </FieldContainer>
          <FieldContainer iconPlacementCss={"iconCss"}>
            <FieldName name={"UPC"} required />
            <Form.Item
              name={"upc"}
              help={touched.upc && errors.upc ? errors.upc : ""}
              validateStatus={touched.upc && errors.upc ? "error" : "success"}
            >
              <Field>
                {({ form: { setFieldValue } }: any) => (
                  <Input
                    type={"text"}
                    className={"form-field_input_2"}
                    value={values?.upc || ""}
                    onChange={(e) => {
                      setFieldValue("upc", e.target.value);
                    }}
                    onBlur={(e) => {
                      setFieldValue("upc", e.target.value);
                    }}
                    placeholder="Enter UPC"
                  />
                )}
              </Field>
            </Form.Item>
          </FieldContainer>
          <FieldContainer iconPlacementCss={"iconCss"}>
            <FieldName name={"Brand"} />
            <Form.Item
              name={"brand"}
              help={touched.brand && errors.brand ? errors.brand : ""}
              validateStatus={
                touched.brand && errors.brand ? "error" : "success"
              }
            >
              <Field>
                {({ form: { setFieldValue } }: any) => (
                  <Input
                    type={"text"}
                    className={"form-field_input_2"}
                    value={values?.brand || ""}
                    onChange={(e) => {
                      setFieldValue("brand", e.target.value);
                    }}
                    onBlur={(e) => {
                      setFieldValue("brand", e.target.value);
                    }}
                    placeholder="Enter product brand"
                  />
                )}
              </Field>
            </Form.Item>
          </FieldContainer>
          <FieldContainer iconPlacementCss={"iconCss"}>
            <FieldName name={"Category"} />
            <Form.Item
              name={"category"}
              help={touched.category && errors.category ? errors.category : ""}
              validateStatus={
                touched.category && errors.category ? "error" : "success"
              }
            >
              <Field>
                {({ form: { setFieldValue } }: any) => (
                  <Input
                    type={"text"}
                    className={"form-field_input_2"}
                    value={values?.category || ""}
                    onChange={(e) => {
                      setFieldValue("category", e.target.value);
                    }}
                    onBlur={(e) => {
                      setFieldValue("category", e.target.value);
                    }}
                    placeholder="Enter product category"
                  />
                )}
              </Field>
            </Form.Item>
          </FieldContainer>
          <FieldContainer iconPlacementCss={"iconCss"}>
            <FieldName name={"Weight"} />
            <Form.Item
              name={"weight"}
              help={touched.weight && errors.weight ? errors.weight : ""}
              validateStatus={
                touched.weight && errors.weight ? "error" : "success"
              }
            >
              <Field>
                {({ form: { setFieldValue } }: any) => (
                  <Input
                    type={"number"}
                    className={"form-field_input_2"}
                    value={values?.weight || ""}
                    onChange={(e) => {
                      setFieldValue("weight", e.target.value);
                    }}
                    onBlur={(e) => {
                      setFieldValue("weight", e.target.value);
                    }}
                    placeholder="Enter product weight"
                  />
                )}
              </Field>
            </Form.Item>
          </FieldContainer>
          <FieldContainer iconPlacementCss={"iconCss"}>
            <FieldName name={"Color"} />
            <Form.Item
              name={"color"}
              help={touched.color && errors.color ? errors.color : ""}
              validateStatus={
                touched.color && errors.color ? "error" : "success"
              }
            >
              <Field>
                {({ form: { setFieldValue } }: any) => (
                  <Input
                    type={"text"}
                    className={"form-field_input_2"}
                    value={values?.color || ""}
                    onChange={(e) => {
                      setFieldValue("color", e.target.value);
                    }}
                    onBlur={(e) => {
                      setFieldValue("color", e.target.value);
                    }}
                    placeholder="Enter product color"
                  />
                )}
              </Field>
            </Form.Item>
          </FieldContainer>
          <FieldContainer iconPlacementCss={"iconCss"}>
            <FieldName name={"Description"} />
            <Form.Item
              name={"description"}
              help={
                touched.description && errors.description
                  ? errors.description
                  : ""
              }
              validateStatus={
                touched.description && errors.description ? "error" : "success"
              }
            >
              <Field>
                {({ form: { setFieldValue } }: any) => (
                  <TextArea
                    className={"form-field_input_2"}
                    rows={4}
                    value={values?.description || ""}
                    onChange={(e) => {
                      setFieldValue("description", e.target.value);
                    }}
                    onBlur={(e) => {
                      setFieldValue("description", e.target.value);
                    }}
                    placeholder="Enter product description"
                  />
                )}
              </Field>
            </Form.Item>
          </FieldContainer>
        </div>
      ),
    },
  ];

  const getUnitOfMeasurementItems = (
    touched: FormikTouched<EditProductModel>,
    errors: FormikErrors<EditProductModel>,
    values: EditProductModel
  ): CollapseProps["items"] => [
    {
      key: "1",
      label: "Units of measurement",
      children: (
        <div className="w-full flex flex-col mt-4">
          <Form.Item
            name={"uom"}
            help={touched.uom && errors.uom ? errors.uom : ""}
            validateStatus={touched.uom && errors.uom ? "error" : "success"}
            className="p-3 border border-dark-200 rounded-[10px]"
          >
            <Field>
              {({ form: { setFieldValue } }: any) => (
                <Checkbox.Group
                  value={values.uom}
                  onChange={(checkedValues) => {
                    setFieldValue("uom", checkedValues);
                    updateUoms(checkedValues);
                  }}
                  className="w-full flex gap-y-4"
                >
                  {options.map((option) => (
                    <Checkbox
                      className="product-checkbox"
                      key={option.value}
                      value={option.value}
                    >
                      <span className="uom-label">{option.label}</span>
                      <div className="flex items-center gap-x-5">
                        <p>Conversion</p>
                        <p>{option?.data?.factor}</p>
                      </div>
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              )}
            </Field>
          </Form.Item>
        </div>
      ),
    },
  ];

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className={`w-full drawer-content-height`}>
            <div className={"mb-5"}>
              <div className="flex items-center gap-x-4">
                <div
                  className={
                    "xl:h-[64px] h-[50px] xl:w-[64px] w-[50px] rounded-full relative"
                  }
                >
                  {imagePreview && !isImageDeleted ? (
                    <img
                      src={imagePreview}
                      alt="Uploaded Preview"
                      className={
                        "xl:h-[64px] h-[50px] xl:w-[64px] w-[50px] rounded-full object-cover"
                      }
                    />
                  ) : (
                    <img
                      src={
                        product?.default_properties?.image_url &&
                        !isImageDeleted
                          ? product?.default_properties?.image_url
                          : QuicstockNoImage
                      }
                      alt="Upload Preview"
                      className={
                        "xl:h-[64px] h-[50px] xl:w-[64px] w-[50px] rounded-full object-cover"
                      }
                    />
                  )}
                  <button
                    type="button"
                    onClick={handleButtonClick}
                    className="h-full w-full rounded-full absolute top-0 bg-black hover:opacity-[0.7] opacity-[0.5] flex items-center justify-center transition-all duration-300"
                  >
                    <QuicstockEditBlank />
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
                {!isImageDeleted && (
                  <button
                    className="bg-dark-300 h-[42px] w-[42px] rounded-full flex items-center justify-center"
                    type="button"
                    onClick={handleDeleteImage}
                  >
                    <QuicstockTrash />
                  </button>
                )}
              </div>
            </div>
            <div className={"mb-5"}>
              <Collapse
                className={`custom-collpse-sm ${hasError(touched, errors) ? "error" : ""}`}
                expandIcon={({ isActive }) => (
                  <RightOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition="right"
                items={getProductDetailsItems(touched, errors, values)}
              />
              {hasError(touched, errors) ? (
                <span className="text-labels text-error-form">
                  Some fields are failing validation
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className={"mb-5"}>
              <Collapse
                className="custom-collpse-sm"
                expandIcon={({ isActive }) => (
                  <RightOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition="right"
                items={getUnitOfMeasurementItems(touched, errors, values)}
              />
            </div>
          </div>
          <div className="w-full left-0 right-0 bottom-0 bg-white">
            <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
            <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
              <div className={"col-1 sm:block hidden"}></div>
              <div className={"col-1"}>
                <Button
                  css={
                    "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                  }
                  state={ButtonState.PRIMARY}
                  text={"Go back"}
                  type={"button"}
                  onClick={cancel}
                />
              </div>
              <div className={"col-1"}>
                <Button
                  css={"w-full !px-0 !rounded-[6px]"}
                  state={ButtonState.PRIMARY}
                  text={"Preview"}
                  type={"submit"}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validator = yup.object().shape({
  name: yup.string().required("Product name is required"),
  upc: yup.string().required("Product UPC is required"),
  // brand: yup.string().required("Product brand is required"),
  // category: yup.string().required("Product category is required"),
  // weight: yup.number().required("Product weight is required"),
  // color: yup.string().required("Product category is required"),
  // description: yup.string().required("Description is required"),
});

export { EditProductForm };
