import React, { FC } from "react";
import "../../styles.scss";
import { ILocationPurchaseOrder } from "../../model";
import { ReactComponent as QuicstockDefaultCheck } from "../../../../assets/svgs/quicstock-default-check.svg";
import { formatCurrency } from "../../../../utils/constants";

const LocationPurchaseOrder: FC<ILocationPurchaseOrder> = ({
  orderNo,
  dateCompleted,
  hideCheck = false,
  quantity = 0,
}) => {
  return (
    <div className="w-full border-[1px] border-[#F9F9F9] rounded-[8px] p-3 grid grid-cols-12 items-center">
      <div className={hideCheck ? "col-span-12" : "col-span-11"}>
        <p className="text-sm-regular">
          Purchase Order: <span className="ml-1 font-semibold">{orderNo}</span>
        </p>
        {dateCompleted && (
          <p className="text-gray-300 text-labels mt-2">
            Date completed:{" "}
            <span className="text-black text-labels">{dateCompleted}</span>
          </p>
        )}

        <div className="flex items-center justify-between mt-2">
          <p className="text-gray-300 text-labels">
            Quantity:{" "}
            <span className="text-black text-labels">
              {formatCurrency(quantity.toString())}
            </span>
          </p>
          <p className="text-gray-300 text-labels">
            Unit price: <span className="text-black text-labels">N300</span>
          </p>
        </div>
      </div>
      {!hideCheck && (
        <div className="col-span-1 flex justify-end">
          <QuicstockDefaultCheck />
        </div>
      )}
    </div>
  );
};

export { LocationPurchaseOrder };
