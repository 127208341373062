/* eslint-disable @typescript-eslint/no-unused-vars */
import { Popover, Table } from "antd";
import React, { FC, useState } from "react";
import "../../styles.scss";
import { IAllUsers } from "../../model";
import {
  itemRender,
  paginationFooter,
  trimAll,
} from "../../../../utils/constants";
import TableActions from "../../../../components/TableActions";
import { ReactComponent as QuicstockMore } from "../../../../assets/svgs/quicstock-more.svg";
import { ReactComponent as QuicstockEye } from "../../../../assets/svgs/quicstock-eye.svg";
import { ReactComponent as QuicstockUploadDoc } from "../../../../assets/svgs/quicstock-upload-doc.svg";
import { ReactComponent as QuicstockTrash } from "../../../../assets/svgs/quicstock-trash.svg";
import { ReactComponent as QuicstockUserRemove } from "../../../../assets/svgs/quicstock-user-remove.svg";
import { UserItemModel } from "../../../../model/user.model";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { capitalize } from "lodash";

const ACTION_STYLE =
  "text-sm-regular mb-4 p-2 gap-x-3 flex items-center cursor-pointer";

const AllUsers: FC<IAllUsers> = ({
  viewUser,
  updateUser,
  disableUser,
  enableUser,
  deleteUser,
  paginationData,
  setPaginationData,
}) => {
  const isLoading = useSelector(
    (state: RootState) => state.users.isUserActionLoading
  );
  const users = useSelector((state: RootState) => state.users.users);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const [open, setOpen] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [id, setId] = useState<any>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [user, setUser] = useState<UserItemModel | undefined>(undefined);

  const handleOpenChange = (newOpen: boolean, record: any) => {
    setOpen(newOpen);
    setCurrentRow(record?.key);
    setId(record?.id);
    setUser({
      ...record?.user,
    } as any);
  };

  const handleMore = (option: "VIEW" | "UPDATE" | "DISABLE" | "ENABLE" | "DELETE") => {
    if (option === "DELETE") {
      setDeleteConfirmation(!deleteConfirmation);
    } else {
      if (option === "VIEW" && viewUser) {
        viewUser(user as any);
      }
      if (option === "UPDATE" && updateUser) {
        updateUser(user as any);
      }
      if (option === "DISABLE" && disableUser) {
        disableUser(user as any);
      }
      if (option === "ENABLE" && enableUser) {
        enableUser(user as any);
      }
      setOpen(false);
    }
  };

  const handleSingleDelete = (e: any) => {
    e.stopPropagation();
    setDeleteConfirmation(false);
    setOpen(false);
    deleteUser && deleteUser(id);
  };

  const columns = [
    {
      title: "First name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone number",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: <QuicstockMore />,
      key: "actions",
      render: (record: any) => (
        <Popover
          content={content}
          trigger="click"
          open={open && currentRow === record?.key}
          placement="bottomLeft"
          onOpenChange={(e) => {
            handleOpenChange(e, record);
          }}
        >
          <div className="cursor-pointer">
            <QuicstockMore />
          </div>
        </Popover>
      ),
    },
  ];

  const content = (
    <div className="w-[210px] table-menu">
      <div className={ACTION_STYLE} onClick={() => handleMore("VIEW")}>
        <QuicstockEye />
        View more
      </div>
      <div className={ACTION_STYLE} onClick={() => handleMore("UPDATE")}>
        <QuicstockUploadDoc />
        Update
      </div>
      <div className={ACTION_STYLE} onClick={() => handleMore("DISABLE")}>
        <QuicstockUserRemove />
        {user?.status === "active" ? "Disable" : "Enable"}
      </div>
      <div
        className={`${ACTION_STYLE} !mb-0 ${deleteConfirmation ? "delete-confirmation" : "delete-no-confirmation"}`}
        onClick={() => handleMore("DELETE")}
      >
        <div className="first">
          <QuicstockTrash />
        </div>
        Delete
        <div className="last" onClick={handleSingleDelete}>
          <QuicstockTrash />
        </div>
      </div>
    </div>
  );

  const data =
    users &&
    users.users?.length > 0 &&
    users.users?.map((user, index) => {
      return {
        key: index,
        first_name: user.first_name ?? "---",
        last_name: user.last_name ?? "---",
        email: user.email ?? "---",
        phonenumber: user?.phonenumber ?? "---",
        status: user?.status ? (
          <div className={`status ${trimAll(user?.status.toLowerCase())}`}>
            {capitalize(user?.status)}
          </div>
        ) : (
          "---"
        ),
        role: user?.role ?? "---",
        id: user?.id,
        user: user,
      };
    });

  const onShowSizeChange = (current: number, pageSize: number) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current: number, pageSize: number) => {
    setPaginationData({
      ...paginationData,
      itemsPerPage: pageSize,
      page: current,
    });
  };

  const handleExport = () => {};
  const handleFilter = () => {};
  const handleDelete = () => {};

  return (
    <>
      <TableActions
        label={"Actions you can perform"}
        handleExport={handleExport}
        handleFilter={handleFilter}
        handleDelete={handleDelete}
      />
      <div className="w-full bg-white extra-filter-table pb-4 overflow-hidden mt-10">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={data as any}
          size={"small"}
          className={"d-table"}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50"],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: users?.total_count ?? users?.users?.length,
            pageSize: defaultPageSize,
            showTotal: (total) =>
              paginationFooter(
                paginationData,
                users?.total_count ?? users?.users?.length,
                total
              ),
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender: (_, type, originalElement) =>
              itemRender(
                _,
                type,
                originalElement,
                users?.total_count ?? users?.users?.length,
                defaultPageSize
              ),
          }}
        />
      </div>
    </>
  );
};

export { AllUsers };
